<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-grid gap-2">
          <router-link :to="'log-text'" class="btn btn-info py-4 px-3 text-start">
            <span class="h2">✍️ Write log entry</span>
            <br>
            <small><i>For end of shift, general log of Alnasir's status</i></small>
          </router-link>
          <router-link :to="'log'" class="btn btn-secondary py-4 px-3 text-start">
            <span class="h2">📄 View care log</span>
          </router-link>
          <hr>
          <router-link :to="'log-urination'" class="btn btn-warning py-4 px-3 text-start">
            <span class="h2">🚽 Record urine output</span>
          </router-link>
          <router-link :to="'log-bm'" class="btn btn-outline-primary py-4 px-3 text-start">
            <span class="h2">💩 Record BM</span>
          </router-link>
          <router-link :to="'contacts'" class="btn btn-outline-secondary py-4 px-3 text-start">
            <span class="h2">📞 Family contact info</span>
          </router-link>
          <hr>
          <h3><i>Coming soon:</i></h3>
          <router-link :to="'log-bp'" class="disabled btn btn-outline-danger py-4 px-3 text-start">
            <span class="h2">❤️ Record BP</span>
          </router-link>
          <router-link :to="'log-exercise'" class="disabled btn btn-outline-secondary py-4 px-3 text-start">
            <span class="h2">🏋️ Record exercise</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
  }),
  async mounted() {
    const { data: me } = await this.$api.get('/me');

    console.log(me);
  },
  computed: {},
}
</script>

<style scoped>
</style>