import Vue from 'vue'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMoment from 'vue-moment';

import App from './App.vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from './router'

const api = axios.create({
  baseURL: '/api',
  timeout: 1000,
  headers: {},
});
api.interceptors.request.use(
  config => {
    if (localStorage.getItem('alnasir-jwt')) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem('alnasir-jwt')}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);
api.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('alnasir-jwt');
    router.replace('/login');
  } else if (error.response?.data?.error?.message) {
    window.alert(error.response.data.error.message);
  }
  console.error(error);
});
window.api = api;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueMoment);

Vue.config.productionTip = false

Vue.prototype.$api = api;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
