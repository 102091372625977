<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="'/'">Home</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">Write care log entry</li>
            </ol>
          </nav>
        </div>
        <form @submit.prevent="submit" class="d-flex gap-1 flex-column">
          <div>
            Please check off all that apply to Alnasir today:<br>
            <label class="tag badge bg-secondary p-2 me-1 my-1 d-inline-flex align-items-center" v-for="tag in tagOptions" :for="tag" :key="tag">
              <input type="checkbox" :id="tag" :value="tag" v-model="tags">
              <span class="ms-1">{{ tag }}</span>
            </label>
          </div>

          <label for="text">Please describe your shift and/or any concerns/updates, what Alnasir ate/drank, etc.</label>
          <textarea id="text" class="form-control mb-2" rows="8" placeholder="" v-model="text" required></textarea>
          <button type="submit" class="btn btn-outline-success"><span class="h3">Save</span></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'LogText',
  components: {
  },
  data: () => ({
    text: '',
    tags: [],
    tagOptions: ['agitated', 'confused', 'slept well', 'restless sleep', 'screaming', 'angry', 'urination issues'],
  }),
  async mounted() {
    this.loadCareLogs();
  },
  methods: {
    async loadCareLogs() {
      const { data } = await this.$api.get('/care-logs');

      this.entries = data;
    },
    async submit() {
      const { text, tags } = this;
      
      if (window.confirm('Are you sure you\'d like to submit?')) {
        await this.$api.post('/care-logs', { text, tags });
        this.$router.push('/');
      }
    },
  },
}
</script>

<style scoped>
form {
  /* min-height: 80vh; */
}

.tag {
  font-size: 1rem;
}
</style>