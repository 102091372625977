<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="'/'">Home</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">Family contact info</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="col-sm-6 my-1" v-for="contact in contacts" :key="contact.name">
        <div class="card">
          <h5 class="card-header">
            {{ contact.name }}
          </h5>
          <div class="card-body">
            <a :href="`tel:${contact.phone}`">{{ contact.phone.replace(/^\+1/, '') }}</a>
            <br>
            {{ contact.note }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'Contacts',
  components: {
  },
  data: () => ({
    contacts: [],
  }),
  async mounted() {
    const { data } = await this.$api.get('/contacts');

    this.contacts = data;
  },
  computed: {},
}
</script>

<style scoped>
</style>