<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="login" class="d-grid gap-2">
          <input class="form-control form-control-lg" type="text" placeholder="Username" v-model="name" required>
          <input class="form-control form-control-lg" type="password" placeholder="Password" v-model="password" required>
          <button type="submit" class="btn btn-secondary py-4 px-3"><span class="h3">Login</span></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'Login',
  components: {
  },
  data: () => ({
    name: '',
    password: '',
  }),
  async mounted() {
  },
  methods: {
    async login() {
      const { data } = await this.$api.post('/auth', {
        name: this.name,
        password: this.password,
      });

      localStorage.setItem('alnasir-jwt', data.token);
      this.$router.replace('/');
    },
  },
}
</script>

<style scoped>
</style>