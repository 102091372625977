<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="'/'">Home</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">Record urine output</li>
            </ol>
          </nav>
        </div>
        <form @submit.prevent="submit" class="d-flex gap-1 flex-column">
          <label for="text">Please describe amount and any concerns/issues</label>
          <input id="text" type="text" class="form-control mb-2" v-model="text" required />
          <button type="submit" class="btn btn-outline-success"><span class="h3">Save</span></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'LogText',
  components: {
  },
  data: () => ({
    text: '',
    tags: [],
    tagOptions: ['agitated', 'confused', 'slept well', 'restless sleep', 'screaming', 'angry', 'urination issues'],
  }),
  async mounted() {
    this.loadCareLogs();
  },
  methods: {
    async loadCareLogs() {
      const { data } = await this.$api.get('/care-logs');

      this.entries = data;
    },
    async submit() {
      const { text } = this;
      
      await this.$api.post('/care-logs', { entryType: 'urine-output', text });
      this.$router.push('/');
    },
  },
}
</script>

<style scoped>
form {
  /* min-height: 80vh; */
}

.tag {
  font-size: 1rem;
}
</style>