<template>
  <div id="app">
    <!-- navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
      <div class="container-fluid">
        <router-link :to="'/'" class="navbar-brand">🏠 Alnasir's Care</router-link>

        <button @click="logout" class="btn btn-link nav-link">Logout</button>
      </div>
    </nav>
    <!-- end navbar -->

    <!-- router window -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    logout() {
      localStorage.removeItem('alnasir-jwt');
      this.$router.push('/login');
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
