<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="'/'">Home</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">Care logs</li>
            </ol>
          </nav>
        </div>
        <div v-for="log in entries" :key="log._id">
          <!-- TEXT ENTRY -->
          <div class="card mb-3" v-if="log.entryType === 'note'">
            <h6 class="card-header">
              <span>📝 Care log</span>
            </h6>
            <div class="card-body">
              <p v-if="log?.tags?.length">
                <span class="text-muted">Tags: </span>
                <strong>{{ log.tags.join(', ') }}</strong>
              </p>
              <p>
                {{ log.text }}
              </p>
            </div>
            <div class="card-footer">
              <div class="d-flex flex-row">
                <span class="me-auto">
                  <b-icon icon="person"></b-icon>&nbsp;
                  <strong>{{ log.authorName }}</strong>
                </span>
                <span>{{ log.createdAt | moment("from", "now") }}</span>
              </div>
            </div>
          </div>
          <!-- URINE OUTPUT -->
          <div class="card px-3 py-1 mb-3" v-if="log.entryType === 'urine-output'">
            <span><strong>🚽 Urine output: </strong>{{ log.text }}</span>
            <div class="d-flex flex-row small-footer">
              <span class="me-auto">
                <b-icon icon="person"></b-icon>&nbsp;
                {{ log.authorName }}
              </span>
              <span>{{ log.createdAt | moment("from", "now") }}</span>
            </div>
          </div>
          <!-- BOWEL MOVEMENT -->
          <div class="card px-3 py-1 mb-3" v-if="log.entryType === 'bowel-movement'">
            <span><strong>💩 Bowel movement: </strong>{{ log.text }}</span>
            <div class="d-flex flex-row small-footer">
              <span class="me-auto">
                <b-icon icon="person"></b-icon>&nbsp;
                {{ log.authorName }}
              </span>
              <span>{{ log.createdAt | moment("from", "now") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'Log',
  components: {
  },
  data: () => ({
    entries: [],
  }),
  async mounted() {
    this.loadCareLogs();
  },
  methods: {
    async loadCareLogs() {
      const { data } = await this.$api.get('/care-logs');

      this.entries = data;
    },
  },
}
</script>

<style scoped>
.card-body {
  white-space: pre-line;
}

.small-footer {
  font-size: 0.75em;
}
</style>