import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Log from '../components/Log.vue'
import Login from '../components/Login.vue'
import LogText from '../components/LogText.vue'
import LogUrination from '../components/LogUrination.vue'
import LogBowelMovement from '../components/LogBowelMovement.vue'
import Contacts from '../components/Contacts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/log',
    name: 'Log',
    component: Log
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/log-text',
    name: 'LogText',
    component: LogText
  },
  {
    path: '/log-urination',
    name: 'LogUrination',
    component: LogUrination
  },
  {
    path: '/log-bm',
    name: 'LogBowelMovement',
    component: LogBowelMovement
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
]

const router = new VueRouter({
  routes
})

export default router
